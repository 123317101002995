.drawer {
  width: 150px;
  flex-shrink: 0;
  position: relative;
  height: auto !important;
}

.beauty {
  color: rgb(156, 35, 255);
}

.dashboard-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: url("https://storage.googleapis.com/msgsndr/ZzAiG00EMvdj21xRbniA/media/67ec78b22d3bdc6d0a7f9308.jpeg") no-repeat center center;
  background-size: cover;
}

.cs-message-list {
  background-color: #f1e7db !important;
}

.chat-box {
  height: 80vh;
  width: 500px;
  border-radius: 30px !important;
  margin-top: 30px;
}

.cs-message__content {
  background-color: #cfe6e7 !important;
}

.cs-button--attachment {
  display: none !important;
}

.cs-message-input {
  border-bottom-left-radius: 20px;
  background-color: #f1e7db !important;
}

.App .cs-message-list .ps {
  background-image: linear-gradient(
    96deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 10%,
    #ddefbb 100%
  );
  transform: translatex(0px) translatey(0px);
}

.cs-message-input__content-editor-wrapper {
  margin-left: 8px !important;
  padding: 15px 15px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.cs-typing-indicator {
  right: 0px !important;
  background-color: rgb(203, 234, 234) !important;
}

@media (max-width: 600px) {
  .chat-box {
    width: 100% !important;
    height: 70vh !important;
    margin: 0px !important;
    padding: 10px;
    box-sizing: border-box;
  }

  .cs-message-list {
    background-color: #f1e7db !important;
    overflow-y: auto !important;
    max-height: calc(70vh - 60px) !important;
    padding: 10px;
  }

  .dashboard-container {
    height: 100vh !important;
    flex-direction: column;
    padding: 0px !important;
    background: url("https://storage.googleapis.com/msgsndr/ZzAiG00EMvdj21xRbniA/media/67ec78b22d3bdc6d0a7f9308.jpeg") no-repeat center center;
    background-size: cover !important;
  }
}

.whole {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.whole-text {
  padding: 5px 10px;
  background-color: rgb(231, 233, 110);
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

.alert-beauty {
  color: rgb(162, 66, 162);
}

.alert-beauty-big {
  font-size: medium;
}

section[aria-label="OuraTalk"] > div > div > div {
  color: rgb(115, 78, 153) !important;
}

section[aria-label="user"] > div > div > div {
  color: rgb(149, 96, 46) !important;
}

.cs-button {
  margin-bottom: 15px !important;
}
