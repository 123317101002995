.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 95vh;
  backdrop-filter: blur(10px);
  border-radius: 0px 0px 15px 15px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgb(215, 215, 215);
  width: calc(100% - 20px);
  max-width: 700px;
  margin: auto;
  border: 2px dashed aqua;
}

.title {
  color: #1b9a75;
  margin-block: 30px;
  font-size: 2rem;
}
