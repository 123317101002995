html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.display-4 {
  width: fit-content;
  border-left: 10px solid rgb(173, 255, 204);
  padding-left: 20px;
  font-weight: 500 !important;
}
.pricing-header {
  max-width: 700px;
}
.lead {
  margin-block: 20px;
  font-weight: 400 !important;
  color: rgb(83, 102, 102);
  width: fit-content;
}
.card-header {
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255),
    rgb(173, 255, 204)
  ) !important;
}
.card-deck {
  margin: auto;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card-deck .card {
  margin: auto;
  min-width: 270px;
}
.btn {
  padding-inline: 15% !important;
}

@media (max-width: 600px) {
  .lead {
    margin-block: 20px;
    font-size: medium !important;
    text-align: left;
  }
}
